import React from 'react';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import AliceCarousel from 'react-alice-carousel';

//@ts-ignore
import Holder from 'react-holder-component'

import './App.scss';
import "react-alice-carousel/lib/alice-carousel.css";

interface GoToTopProps {
	scrollStepInPx: any;
	delayInMs: any;
}

library.add(fab, fas, far)

class GoToTop extends React.Component<GoToTopProps>  {
	state = {
         intervalId: 0,
         thePosition: false
    };
	 
	componentDidMount() {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                this.setState({ thePosition: true })
            } else {
                this.setState({ thePosition: false })
            }
        });
        window.scrollTo(0, 0);
    }
	
	onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }
	
	scrollToTop = () => {
		let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
	}
	
	renderGoTopIcon = () => {
        if (this.state.thePosition){
            return (
                <div className="go-top" onClick={this.scrollToTop}>
                    Haut de page
                </div>
            )
        }
    }
	
	render(){
        return (
            <React.Fragment>
                {this.renderGoTopIcon()}
            </React.Fragment>
        )
    }
};

const App: React.FC = () => {
	const i18n = {
		menu: {
			menu1: "Présentation",
			menu2: "Services",
			menu3: "Projets Réalisés",
			menu4: "Certifications"			
		},
		sections: {
			"title1": "Présentation",
			"title2": "Services",
			"title3": "Projets Réalisés",
			"title4": "Certifications",
			"title5": "Où nous intervenons",
			"title6": "Story de A-Z"
		}
	}
	
	return (
		<div className="app">
			<span id="top"></span>
			<header className="app-header">
				<Container className="m-0 m-sm-auto p-0 p-sm-auto">
					<Row className="no-gutters text-center">
						<Col xs={{span: 6, order: 2}} md={{order: 1}} className="menu-item my-md-auto">
							<Nav.Link href="#presentation">{i18n.menu.menu1}</Nav.Link>
						</Col>
						<Col xs={{span: 6, order: 3}} md={{order: 2}} className="menu-item my-md-auto">
							<Nav.Link href="#travaux">{i18n.menu.menu2}</Nav.Link>
						</Col>
						<Col xs={{span: 12, order: 1}} md={{order: 3}} className="my-2 my-md-0">
							<Image src="./logo.png" />
						</Col>
						<Col xs={{span: 6, order: 4}} md={{order: 4}} className="menu-item my-md-auto">
							<Nav.Link href="#projets">{i18n.menu.menu3}</Nav.Link>
						</Col>
						<Col xs={{span: 6, order: 4}} md={{order: 4}} className="menu-item my-md-auto">
							<Nav.Link href="#certifications">{i18n.menu.menu4}</Nav.Link>
						</Col>
					</Row>
				</Container>
			</header>	  
			<div className="app-content">		
				<Container className="m-0 m-sm-auto p-0 p-sm-auto">
					<section className="app-section">					
						<Row className="no-gutters">
							<Col xs={{span: 6}} md={{span: 3}} className="text-center">
								<span className="section-icon"><FontAwesomeIcon icon="bath" size="4x"></FontAwesomeIcon></span>
								<h3>Création<br/>Rénovation</h3>
								<ul className="text-left">
									<li>Salle de bain</li>
									<li>Douche à l'italienne</li>
									<li>Cuisine</li>
									<li>Carrelage</li>
									<li>Faux-plafonds</li>
								</ul>
							</Col>
							<Col xs={{span: 6}} md={{span: 3}} className="text-center">
								<span className="section-icon"><FontAwesomeIcon icon="faucet" size="4x"></FontAwesomeIcon></span>
								<h3>Plomberie</h3>
								<ul className="text-left">
									<li>Recherche de fuites, changement de syphon, bonde, groupe de sécurité</li>
									<li>Changement de lavabo/évier, baignoire, WC, bidet...</li>
									<li>Canalisation de gaz</li>
								</ul>
							</Col>
							<Col xs={{span: 6}} md={{span: 3}} className="text-center">
								<span className="section-icon"><FontAwesomeIcon icon="thermometer-full" size="4x"></FontAwesomeIcon></span>
								<h3>Chauffage</h3>
								<ul className="text-left">
									<li>Création et extension d'installation</li>
									<li>Pose de radiateurs</li>
									<li>Pose de chaudière gaz, condensation et autres</li>
									<li>Pose de pompe à chaleur</li>
									<li>Installation de climatisation</li>
								</ul>
							</Col>
							<Col xs={{span: 6}} md={{span: 3}} className="text-center">								
								<span className="section-icon"><FontAwesomeIcon icon="wrench" size="4x" className="p-10"></FontAwesomeIcon></span>
								<h3>Entretien<br/>Réparation</h3>
								<ul className="text-left">
									<li>Entretien d'adoucisseurs, filtre, ...</li>
									<li>Entretien de réseaux d'assainissement</li>
									<li>Désobstruction de réseaux</li>
									<li>Réparation de canalisations d'eau</li>
									<li>Réparation de réseaux d'assainissement</li>
								</ul>
							</Col>
						</Row>
					</section>
					<section className="app-section">					
						<h2 id="projets" className="app-section-title">{i18n.sections.title6}</h2>
						<AliceCarousel buttonsDisabled dotsDisabled items={[1, 2, 3].map((i) => (<h2 key={i}>{i}</h2>))} autoPlay autoPlayInterval={2000} responsive={{ 0: { items: 1 },  768: { items: 3 } }}>							
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/stories/1/story_1_A.jpg")} />
								<div className="photo-overlay">
									<header>Avant</header>
								</div>
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/stories/1/story_1_B.jpg")} />
								<div className="photo-overlay">
									<header>Avant</header>
								</div>
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/stories/1/story_1_C.jpg")} />
								<div className="photo-overlay">
									<header>Après</header>
								</div>
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/stories/1/story_1_D.jpg")} />
								<div className="photo-overlay">
									<header>Après</header>
								</div>
							</Container>
						</AliceCarousel>
					</section>					
					<section className="app-section">					
						<h2 id="projets" className="app-section-title">{i18n.sections.title3}</h2>
						<AliceCarousel buttonsDisabled dotsDisabled items={[1, 2, 3].map((i) => (<h2 key={i}>{i}</h2>))} autoPlay autoPlayInterval={2000} responsive={{ 0: { items: 1 },  768: { items: 3 } }}>							
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_1.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_2.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_3.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_4.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_5.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_6.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_7.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/projets/projet_8.jpg")} />
							</Container>
						</AliceCarousel>
					</section>
					<section className="app-section">					
						<h2 id="travaux" className="app-section-title">{i18n.sections.title2}</h2>
						<AliceCarousel buttonsDisabled dotsDisabled items={[1, 2, 3].map((i) => (<h2 key={i}>{i}</h2>))} autoPlay autoPlayInterval={2000} responsive={{ 0: { items: 1 },  768: { items: 3 } }}>							
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/travaux/photo-1.jpg")} />
								<div className="photo-overlay">
									<header>Urgence</header>
								</div>
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/travaux/photo-2.jpg")} />
								<div className="photo-overlay">
									<header>Inspection</header>
								</div>
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/travaux/photo-3.jpg")} />
								<div className="photo-overlay">
									<header>Réparation</header>
								</div>
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/travaux/photo-4.jpg")} />
								<div className="photo-overlay">
									<header>Installation</header>
								</div>
							</Container>
						</AliceCarousel>
					</section>
					<section className="app-section">					
						<h2 id="certifications" className="app-section-title">{i18n.sections.title4}</h2>
						<AliceCarousel buttonsDisabled dotsDisabled items={[1, 2, 3].map((i) => (<h2 key={i}>{i}</h2>))} autoPlay autoPlayInterval={2000} responsive={{ 0: { items: 1 },  768: { items: 3 } }}>							
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/certifications/certification-1.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/certifications/certification-4.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/certifications/certification-5.jpg")} />
							</Container>
							<Container fluid className="m-0 p-0">
								<Image src={require("./images/certifications/certification-6.jpg")} />
							</Container>
						</AliceCarousel>
					</section>					
					<section className="app-section">					
						<h2 id="zone-intervention" className="app-section-title">{i18n.sections.title5}</h2>
						<div>&nbsp;</div>
					</section>
				</Container>
			</div>
			<footer className="app-footer">
				<Container>
					<Row className="no-gutters">
						<Col xs={{span:12, order:2}} md={{span:4, order: 1}} className="text-center text-sm-left p-4" itemScope itemType="http://schema.org/LocalBusiness">
							<strong><span itemProp="name">Yris Plomberie</span></strong><br/>
							<address itemProp="address">
								9, Le Creux Chemin<br/>
								91780 Chalô-Saint-Mars<br/>
								SIRET 75376130300027
							</address>
						</Col>
						<Col xs={{span:12, order:1}} md={{span:4, order: 2}} className="text-center">
							<p><small>&copy; copyright 2019 - Yris Plomberie</small></p>
						</Col>
						<Col xs={{span:12, order:3}} md={{span:4, order: 3}} className="text-center text-sm-right">
							<p>
								<Nav.Link href="https://www.facebook.com/yrisplomberie/"><FontAwesomeIcon icon={['fab', 'facebook']} size="3x" /></Nav.Link>
								<Nav.Link href=""><FontAwesomeIcon icon={['fab', 'instagram']} size="3x" /></Nav.Link>
								<Nav.Link href="mailto:yris.thomas@gmail.com"><FontAwesomeIcon icon={['fas', 'envelope']} size="3x" /></Nav.Link>
								<Nav.Link href="tel:+33786110439"><FontAwesomeIcon icon={['fas', 'mobile-alt']} size="3x" /></Nav.Link>
							</p>
						</Col>
					</Row>
				</Container>
			</footer>
			<GoToTop scrollStepInPx="30" delayInMs="10" />
		</div>
	);
};

export default App;
